import interacted from '$lib/stores/interacted';
import volume from '$lib/stores/volume';

export const audio = async (src, vol=volume.value) => {
    if (!interacted.value) return false;
    if (!vol) return true;

    const track = new Audio(src);

    return new Promise(resolve => {
        track.oncanplaythrough = () => {
            try {
                track.volume = vol / 100;
                track.play();
                resolve(true);
            } catch (e) { // Requires user interaction to play the sound
                resolve(false);
            }
        };
    });
}

export default audio;
export const click = () => audio('/static/audio/click.ogg');
