import { writable } from 'svelte/store';
import { browser } from '$app/environment';

const getHandler = (init) => {
    return {
        parse(val) {
            if (val === null) return init;
            
            try {
                switch (typeof init) {
                    case 'object':  return JSON.parse(val);
                    case 'number':  return parseFloat(val);
                    case 'boolean': return val === 'true';
                    default:        return val;
                }
            } catch (e) {
                return init;
            }
        },
        stringify(val) {
            switch (typeof init) {
                case 'object': return JSON.stringify(val);
                default: return val;
            }
        },
    }
}

export default function(key, init, syncTabs=true, initHandler=getHandler) {
    if (!browser) return writable(init);

    const handler = initHandler(init);
    const stored = handler.parse(localStorage.getItem(key));
    const store = writable(stored);

    store.subscribe((val) => localStorage.setItem(key, handler.stringify(val)));
    store.clear = () => store.set(init);

    const storageEventHandler = (event) => {
        if (event.key !== key) return;
        
        let val = event.newValue;
        if (event.oldValue === val) return;
        
        store.set(handler.parse(val));
    };

    if (window && syncTabs) window.addEventListener('storage', storageEventHandler);
    store.unregister = () => window.removeEventListener('storage', storageEventHandler);

    return store;
}
