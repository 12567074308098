import { writable } from 'svelte/store';
import { browser } from '$app/environment';

export const interacted = writable(false);
interacted.subscribe(v => interacted.value = v);

if (browser) {
    const onInteraction = () => {
        interacted.set(true);

        document.removeEventListener('click',   onInteraction);
        document.removeEventListener('keydown', onInteraction);

        console.log('Interacted');
    };

    document.addEventListener('click',   onInteraction);
    document.addEventListener('keydown', onInteraction);
}

export default interacted;